import {defineStore} from 'pinia'
import CONSTANTS from '@/config/CONSTANTS.js'
import {baseApi} from '@/extensions/http'
import {session} from '@/extensions/session'
import {useNoticeStore} from '@/stores/notice.js'
import {useOnboardingStore} from '@/stores/onboarding.js'
import {useOrganizationStore} from '@/stores/organization.js'
import router from '@/router'

export const useAuthStore = defineStore('auth', {
    state: () => {
        return {
            logged_in: false,
            ready: false,
            user: null
        }
    },
    actions: {
        async confirm(payload) {
            try {
                const {data} = await baseApi.post(CONSTANTS.API_ENDPOINTS.AUTH.CONFIRM, payload)

                if (data) this.setUser(data)
            } catch (exception) {
                useNoticeStore().report('errors.server.auth.confirm')
            }
        },
        async login(payload) {
            try {
                await baseApi.get(CONSTANTS.API_ENDPOINTS.AUTH.SANCTUM_CSRF)
                await baseApi.post(CONSTANTS.API_ENDPOINTS.AUTH.LOGIN, payload)

                return true
            } catch (exception) {
                useNoticeStore().report('errors.server.auth.login')
                return false
            }
        },
        async logout() {
            try {
                this.ready = false
                await baseApi.post(CONSTANTS.API_ENDPOINTS.AUTH.LOGOUT)

                this.logged_in = false
                session.deleteExpiration()
                useOnboardingStore().reset()
                useOrganizationStore().reset()
                await router.push({name: CONSTANTS.ROUTES.ORGANIZATION.INDEX})
            } catch (exception) {
                useNoticeStore().report('errors.server.auth.logout')
            }
            this.ready = true
        },
        async reconnect() {
            try {
                if (!session.isExpired()) {
                    await baseApi.get(CONSTANTS.API_ENDPOINTS.AUTH.SANCTUM_CSRF)
                    const {data} = await baseApi.get(CONSTANTS.API_ENDPOINTS.USER.ME)

                    if (data) this.setUser(data)
                }
            } catch (exception) {
                useNoticeStore().report('errors.server.auth.reconnect')
            }

            this.ready = true
        },
        setUser(data) {
            this.user = data.id
            useOrganizationStore().sync(data.organization)
            this.logged_in = true
            session.saveExpiration()
        }
    }
})