import {createI18n} from 'vue-i18n'

import enDefault from '@/i18n/en/default.js'
import enErrors from '@/i18n/en/errors.js'
import enLabels from '@/i18n/en/labels.js'
import enOnboarding from '@/i18n/en/onboarding.js'
import enOrganization from '@/i18n/en/organization.js'

import frDefault from '@/i18n/fr/default.js'
import frErrors from '@/i18n/fr/errors.js'
import frLabels from '@/i18n/fr/labels.js'
import frOnboarding from '@/i18n/fr/onboarding.js'
import frOrganization from '@/i18n/fr/organization.js'

export default createI18n({
    locale: 'fr',
    fallbackLocale: 'fr',
    messages: {
        en: {
            ...enDefault,
            errors: enErrors,
            labels: enLabels,
            onboarding: enOnboarding,
            organization: enOrganization
        },
        fr: {
            ...frDefault,
            errors: frErrors,
            labels: frLabels,
            onboarding: frOnboarding,
            organization: frOrganization
        }
    }
})
