import {defineStore} from 'pinia'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import CONSTANTS from '@/config/CONSTANTS.js'
import {baseApi} from '@/extensions/http/index.js'
import {useAuthStore} from '@/stores/auth.js'
import {useNoticeStore} from '@/stores/notice.js'
import {useOrganizationStore} from '@/stores/organization.js'

export const useAppStore = defineStore('app', {
    state: () => {
        return {
            data: null,
            Echo: null,
            organizations: {}
        }
    },
    actions: {
        initWebSockets() {
            if (!import.meta.env.VITE_REVERB_DISABLED) {
                window.Pusher = Pusher

                window.Echo = new Echo({
                    broadcaster: 'reverb',
                    key: import.meta.env.VITE_REVERB_APP_KEY,
                    wsHost: import.meta.env.VITE_REVERB_HOST,
                    wsPort: import.meta.env.VITE_REVERB_PORT,
                    wssPort: import.meta.env.VITE_REVERB_PORT,
                    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
                    enabledTransports: ['ws', 'wss'],
                    authorizer: (channel, options) => {
                        return {
                            authorize: async (socketId, callback) => {
                                try {
                                    const response = await baseApi.post('/api/broadcasting/auth', {
                                        socket_id: socketId,
                                        channel_name: channel.name
                                    })

                                    callback(false, response.data)
                                } catch (exception) {
                                    callback(true, exception)
                                }
                            }
                        }
                    }
                })
            }
        },
        async getOrganization(id) {
            if (this.organizations[id] && this.organizations[id]['offer_description']) {
                return this.organizations[id]
            }

            const org = await useOrganizationStore().get(id)
            this.setOrganization(id, org)

            return org
        },
        async getOrganizations() {
            try {
                const {data} = await baseApi.get(CONSTANTS.API_ENDPOINTS.ORGANIZATION.INDEX)
                this.organizations = data.data
            } catch (exception) {
                useNoticeStore().report("errors.server.organization.index")
            }
        },
        async init() {
            try {
                const {data} = await baseApi.get(CONSTANTS.API_ENDPOINTS.DATA)
                this.data = data

                this.initWebSockets()

                if (!useAuthStore().logged_in) {
                    await useAuthStore().reconnect()
                }
            } catch (exception) {
                useNoticeStore().report("errors.server.init")
            }
        },
        setOrganization(id, payload) {
            this.organizations[id] = payload
        }
    }
})