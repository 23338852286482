import {defineStore} from 'pinia'
import CONSTANTS from '@/config/CONSTANTS.js'

export const useModalStore = defineStore('modal', {
    state: () => {
        return {
            data: null,
            type: null
        }
    },
    actions: {
        close() {
            this.type = null
            this.data = null
        },
        openImageImport() {
            this.type = CONSTANTS.MODAL_TYPES.IMAGE_IMPORT
        },
        openMediaCaptionUpdate(mediaIndex) {
            this.type = CONSTANTS.MODAL_TYPES.MEDIA_CAPTION_UPDATE
            this.data = mediaIndex
        },
        openMediaShow(media) {
            this.type = CONSTANTS.MODAL_TYPES.MEDIA_SHOW
            this.data = media
        },
        openYoutubeVideoImport() {
            this.type = CONSTANTS.MODAL_TYPES.YOUTUBE_VIDEO_IMPORT
        }
    }
})