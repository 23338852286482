<template>
  <div v-if="media">
    <h2 class="text-4xl mb-8">{{ $t('media.add_legend') }}</h2>
    <form action="" @submit.prevent="submit">
      <FormInput :label="$t('labels.caption')" v-model="form.fields.caption" name="caption"/>
      <div class="grid grid-cols-[1fr_max-content] gap-8 w-full mt-8">
        <img v-if="media.type === 'image'" :src="media.source" alt="" :class="mediaClasses">
        <iframe v-if="media.type === 'youtube'"
                :src=" `https://www.youtube.com/embed/${media.video_id}?autoplay=0&cc_load_policy=1&controls=2&rel=0&enablejsapi=1`"
                :class="mediaClasses"/>
        <button class="button mt-auto" type="submit" :disabled="!valid || $organization.updating">{{ $t('submit') }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import {FormInput} from '@/components/form/inputs'
import {FormValidMixin} from '@/components/form/mixins'

export default {
  name: 'MediaCaptionUpdateModal',
  mixins: [FormValidMixin],
  components: {FormInput},
  data() {
    return {
      mediaClasses: 'aspect-video object-cover w-1/5 rounded',
      form: {
        fields: {
          caption: {
            value: '',
            valid: false
          }
        }
      }
    }
  },
  computed: {
    media() {
      return this.$organization.data.medias[this.$modal.data]
    }
  },
  methods: {
    async submit() {
      if (this.valid) {
        this.media.caption = this.form.fields.caption.value

        if (this.media.id) {
          await this.$organization.updateMediaCaption(this.media, this.form.fields.caption.value)
        }

        this.$modal.close()
      }
    }
  },
  mounted() {
    if (this.media && this.media.caption) {
      this.form.fields.caption = {
        value: this.media.caption,
        valid: true
      }
    }
  }
}
</script>