export default {
    ai: {
        title: 'Quels sont les capacités en IA de l\'organisation?'
    },
    competences: {
        title: 'La catégorisation de l\'organisation est-elle adéquate?'
    },
    contact: {
        title: 'Les coordonnées recueillies sont-elles exactes?'
    },
    expertises: {
        title: 'Les expertises particulières sont-elles bien définies?'
    },
    intro: {
        description: 'Devenez une ressource incontournable pour les entreprises cherchant à développer ou adopter l\'IA. Un questionnaire facile et guidé vous aidera à créer une fiche efficace afin d\'attirer les bons partenaires. Maximisez votre potentiel et connectez-vous avec des organisations prêtes à innover grâce à vos solutions aujourd\'hui!',
        hint: 'Prend environ 10 minutes à remplir',
        title: 'Augmentez la <em>visibilité</em> de votre organisation avec Miria'
    },
    knowledge: {
        title: 'Les expertises sont-elles bien définies?'
    },
    links: {
        description: 'Vous pouvez inclure dans le profil de l\'organisation des liens vers des nouvelles, des études de cas, des projets, des collaborations et tout ce qui peut montrer ses bons coups aux potentiels partenaires qui visitent Miria.',
        title: 'Souhaitez-vous ajouter des liens utiles?'
    },
    logo: {
      description: 'Nous recommandons d\'importer la version horizontale du logotype de votre organisation en SVG, PNG ou en JPG. Obtenez un aperçu de son affichage dans le répertoire Miria ci-dessous.',
      title: 'Quel est le logo de l\'organisation?'
    },
    medias: {
        description: 'Vous pouvez inclure dans votre profil des photos et des vidéos représentatives de votre offre de services et/ou de votre produit. C\'est le moment de mettre en valeur l\'organisation et de la présenter plus concrètement aux potentiels partenaires qui visitent Miria.',
        subtitle: 'Médias pertinents sélectionnés sur le site de l\'organisation',
        title: 'Souhaitez-vous ajouter des photos et des vidéos à votre profil?'
    },
    offer_description: {
        title: 'L\'offre de l\'organisation est-elle bien définie?'
    },
    outro: {
        contact: 'Vous avez des suggestions d\'amélioration pour le processus d\'inscription? La plateforme est en pleine évolution et votre avis est précieux. N\'hésitez pas à nous contacter pour faire part de votre avis!',
        description: 'Merci de contribuer à l\'enrichissement de l\'écosystème de l\'intelligence artificielle au Québec et de permettre à plus d\'entreprises et d\'organisations de découvrir les bénéfices de l\'innovation. Miria est en constante évolution et votre aide nous est précieuse.',
        title: 'Bienvenue sur Miria!'
    },
    partnership_benefits: {
        title: 'L\'avantage d\'un partenariat est-il bien défini?'
    },
    preview: {
        description: 'Voici un aperçu de la fiche d\'organisation qui sera affichée sur Miria créée avec les informations que vous avez saisies. Si vous souhaitez modifier des sections, cliquez sur l\'information appropriée ci-dessous ou revenez aux pages précédentes.'
    },
    scraping_preview: {
        description: 'Voici un aperçu des informations récoltées sur votre site web. Passez à la prochaine étape pour commencer à valider les données.'
    },
    sections: {
        adds: 'Ajouts',
        analysis: 'Analyse',
        confirm: 'Confirmation',
        contact: 'Coordonnées',
        details: 'Détails',
        offer: 'Offre'
    },
    quotes: {
        0: 'Le Québec est au premier rang des publications en IA les plus citées au monde.',
        1: 'Plus de 500 entreprises oeuvrent dans le domaine de l\'IA au Québec.',
        2: 'Il existe au Québec plus de 250 acteurs de soutien spécialisés en IA qui accompagnent les entrepreneurs d\'ici.',
        3: 'Le Québec est au premier rang du nombre d’ingénieurs en IA par million de travailleurs.',
        4: 'Depuis plusieurs années, les gouvernements du Québec et du Canada ont investi près de 23 millions de dollars dans les pôles de recherche et les instituts spécialisés pour l\'étude de l\'IA responsable.'
    },
    socials: {
        title: 'Les liens des médias sociaux sont-ils les bons?'
    },
    type: {
        title: 'Le type d\'organisation est-il approprié?'
    },
    url: {
        description: 'Indiquez l\'URL de votre site afin que Miria l\'analyse et vous aide à construire votre fiche. L\'opération peut prendre quelques minutes. Vous pourrez ensuite valider les informations, approuver les contenus générés par Miria et modifier les détails.',
        title: 'Quel est le site web principal de l\'organisation?'
    }
}