<template>
  <Transition name="fade" mode="out-in">
    <div v-if="ready" class="w-full h-full">
      <Transition name="fade" mode="out-in">
        <div v-if="isDesktop" class="w-full h-full">
          <Navigation/>
          <RouterView v-slot="{Component}">
            <Transition name="fade" mode="out-in">
              <component :is="Component" :key="$route.name"/>
            </Transition>
          </RouterView>
          <Modal/>
          <ServerNotice/>
        </div>
        <p v-else class="h-full w-full flex justify-center items-center">{{ $t('desktop_only') }}</p>
      </Transition>
    </div>
    <Loader v-else/>
  </Transition>
  <Background/>
</template>

<script>
import {Modal} from '@/components/modal'
import {Navigation} from '@/components/navigation'
import {Background, Loader, ServerNotice} from '@/components/small'

export default {
  name: 'App',
  components: {Background, Loader, Modal, Navigation, ServerNotice},
  data() {
    return {
      isDesktop: false,
      ready: false
    }
  },
  async mounted() {
    await this.$app.init()

    window.addEventListener('resize', this.handleResize)
    this.handleResize()

    this.ready = true
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.isDesktop = window.innerWidth > 1000
    }
  },
}
</script>