export default {
    add: {
        image: 'Add an image file',
        youtube: 'Add a YouTube link'
    },
    ai_employees: 'Employees dedicated to AI',
    cancel: 'Cancel',
    clipboard: {
        copied: 'URL copied to clipboard!',
        failed: 'Oops! Something went wrong.'
    },
    code: 'Authentication code',
    contact: {
        body: 'Hello, I recently visited the Miria platform and I noticed that ...',
        subject: 'Suggestions to improve the Miria platform'
    },
    contact_us: 'Contact us!',
    data: {
        analysis: 'Data analysis',
        gathering: 'Data gathering',
        generation: 'Content generation',
        transformation: 'Data transformation'
    },
    desktop_only: 'Desktop only',
    did_you_know: 'Did you know...',
    email: 'Email',
    enter: 'Enter',
    experience_years: 'Years of experience',
    keywords: {
        hide: 'Hide the keywords',
        show: 'Show the keywords'
    },
    login: {
        codes: 'For the development phase, the authentication codes must be retrieved at <a href="CODES_URL" target="_blank">CODES_URL</a>, not by email.',
        description: 'A 6-digit code will be sent to the address below to complete authentication. <br>If this is your first visit to the platform, enter the email address you wish to associate with your organization.',
        title: 'Login'
    },
    logout: 'Logout',
    max_selection: ':current of maximum :max selected',
    media: {
        add_image: 'Add an image',
        add_legend: 'Add a legend to the media',
        add_youtube: 'Add a Youtube Video'
    },
    miria: {
        ask: 'Ask Miria'
    },
    password: 'Password',
    photo: {
        choose: 'Choose Photo',
        drag_drop: 'Drag & drop or select a photo from your computer'
    },
    press_on: 'Press on',
    projects_completed: 'Projects completed',
    private: 'Private',
    public: 'Public',
    save: 'Save',
    send: 'Send',
    share: 'Share this profile',
    shift: 'Shift',
    start: 'Start',
    status: 'Status',
    submit: 'Submit',
    to_add_links: 'to add links',
    trademark: '© 2024. All rights reserved. Miria is a project powered by the Québec Innovation Council.',
    visit_website: 'Visit their website'
}