export default {
    ai_activities_employee_count: 'Number of employees dedicated to AI within the organization',
    ai_activities_project_count: 'Estimated number of AI projects completed',
    ai_activities_start_year: 'Year the organization started its AI activities',
    begin_validation: 'Start the validation',
    caption: 'Media Description',
    choices: {
        no: 'No, I would like to specify some things',
        yes: 'Yes, it is perfect!'
    },
    competences: 'Issues and needs addressed by the organization',
    contact: 'Organization\'s contact email',
    definitions: {
        expertises: 'Does this text accurately represent the mentioned expertise?',
        instruction: 'How can the proposed content better describe the offer?',
        knowledge: 'Does this definition accurately represent the expertise of the organization?',
        offer_description: 'Does this definition accurately represent the organization\'s offering?',
        partnership_benefits: 'Does this text accurately represent what a partner can expect to gain from collaborating with you?',
        placeholder: 'Describe to Miria how to modify the content as you would in a normal conversation.'
    },
    expertises: 'Specific expertise',
    facebook: 'URL of the organization\'s Facebook page',
    file_select: 'Select a file',
    knowledge: 'Definition of expertise',
    linkedin: 'URL of the organization\'s LinkedIn page',
    logo: 'Organization\'s logo (must not exceed 200 KB)',
    name: 'Name of the organization',
    offer_description: 'Offer description',
    organization_type: 'Organization Type',
    page_url: 'Page URL',
    partnership_benefits: 'Partnership benefits - Tangible results',
    preview: 'Preview',
    status: 'Organization status',
    target_audiences: 'Audiences targeted by the organization',
    website: 'URL of the organization\'s main website',
    x: 'URL of the organization\'s X page',
    youtube: 'URL of the video'
}