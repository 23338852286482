export default {
    ai_activities_employee_count: 'Nombre d\'employés dédiés à l\'IA au sein de l\'organisation',
    ai_activities_project_count: 'Estimation du nombre de projets en IA réalisés',
    ai_activities_start_year: 'Année où l\'organisation a débuté ses activités en IA',
    begin_validation: 'Débuter la validation',
    caption: 'Description du média',
    choices: {
        no: 'Non, j\'aimerais préciser des choses',
        yes: 'Oui, elle est parfaite!'
    },
    competences: 'Problématiques et besoins auxquels l\'organisation répond',
    contact: 'Adresse courriel contact de l\'organisation',
    definitions: {
        expertises: 'Ce texte représente-t-il bien les expertises mentionnées?',
        instruction: 'Comment le contenu proposé peut-il mieux décrire l\'offre?',
        knowledge: 'Cette définition représente-t-elle bien l\'expertise de l\'organisation?',
        offer_description: 'Cette définition représente-t-elle bien l\'offre de l\'organisation?',
        partnership_benefits: 'Ce texte représente-t-il bien ce qu\'un partenaire peut espérer obtenir d\'une collaboration avec vous?',
        placeholder: 'Décrivez à Miria comment modifier le contenu comme vous le feriez dans une conversation normale.'
    },
    expertises: 'Expertises particulières',
    facebook: 'URL de la page Facebook de l\'organisation',
    file_select: 'Sélectionner un fichier',
    knowledge: 'Définition de l\'expertise',
    linkedin: 'URL de la page LinkedIn de l\'organisation',
    logo: 'Logotype de l\'organisation (ne doit pas dépasser 200 Ko)',
    name: 'Nom de l\'organisation',
    offer_description: 'Description de l\'offre',
    organization_type: 'Type d\'organisation',
    page_url: 'URL de la page',
    partnership_benefits: 'Bénéfices du partenariat - Résultats concrets',
    preview: 'Aperçu',
    status: 'Statut de l\'organisation',
    target_audiences: 'Publics à qui s\'adresse l\'organisation',
    website: 'URL du site principal de l\'organisation',
    x: 'URL de la page X de l\'organisation',
    youtube: 'URL de la vidéo'
}