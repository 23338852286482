export default {
    max_2mb: 'File too large. (Max 2mb)',
    special_characters: "Special characters are not allowed.",
    server: {
        auth: {
            confirm: 'Authentication could not be completed.',
            login: 'The email address provided is invalid or unavailable.',
            logout: 'A problem occurred during logout.',
            reconnect: 'Session expired.'
        },
        content: {
            regen: 'Content could not be regenerated.'
        },
        init: 'A problem occurred during initialization, please refresh the page.',
        media: {
            caption: 'The caption could not be updated.',
            delete: 'The media could not be deleted.',
            logo: 'The logo could not be updated.',
            upload: 'The media could not be updated.'
        },
        organization: {
            index: 'Organizations could not be retrieved.',
            show: 'The organization could not be retrieved.',
            store: 'The organization could not be created.',
            update: 'The organization could not be updated.',
        }
    }
}