import {defineStore} from 'pinia'
import i18n from '@/i18n'

export const useNoticeStore = defineStore('notice', {
    state: () => {
        return {
            message: null,
            timeout: null,
            ttl: 5000
        }
    },
    actions: {
        clear() {
            if (this.timeout) {
                clearTimeout(this.timeout)
            }

            this.message = null
        },
        report(message, translate = true) {
            this.message = translate ? i18n.global.t(message) : message

            if (this.timeout) {
                clearTimeout(this.timeout)
            }

            this.timeout = setTimeout(this.clear, this.ttl)
        }
    }
})