<template>
  <div class="absolute top-0 left-0 w-full h-[100dvh] overflow-hidden -z-10">
    <img src="@/assets/pattern/grid.png" alt="" class="absolute top-[10%] w-[75dvw] left-0 -translate-x-[45%]">
    <img src="@/assets/pattern/grid.png" alt="" class="absolute top-[10%] w-[75dvw] right-0 translate-x-[45%]">
    <div
        class="w-[90dvw] aspect-square block fixed top-0 left-0 rounded-full -translate-x-1/2 -translate-y-1/2 -z-10 bg-[radial-gradient(closest-side_at_50%_50%,#FFFFFF15_0%,#FFFFFF00_100%)]"/>
    <div
        class="w-[120dvw] aspect-square block fixed top-0 right-0 rounded-full translate-x-[40%] -translate-y-[40%] -z-10 bg-[radial-gradient(closest-side_at_50%_50%,#6464ED15_0%,#6464ED00_100%)]"/>
  </div>
</template>

<script>
export default {
  name: 'Background'
}
</script>