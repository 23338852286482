export const session = {
    LOCAL_STORAGE_EXPIRATION_KEY: 'session_expiration',

    deleteExpiration() {
        localStorage.removeItem(session.LOCAL_STORAGE_EXPIRATION_KEY)
    },
    isExpired() {
        const expiration = localStorage.getItem(session.LOCAL_STORAGE_EXPIRATION_KEY)
        return !expiration || Date.now() > new Date(expiration)
    },
    saveExpiration() {
        let expiration = Date.now()
        expiration = new Date(expiration).setDate(new Date(expiration).getDate() + 31)
        localStorage.setItem(session.LOCAL_STORAGE_EXPIRATION_KEY, expiration.toString())
    }
}