<template>
  <component :is="component" :to="to" class="border-b border-white mr-2 pb-2 group transition-[padding_margin] hover:pr-2 hover:mr-0">
    <span>{{ label }}</span>
    <Icon :id="icon" class="text-xs mb-0.5 ml-2 transition-[transform] group-hover:translate-x-2"/>
  </component>
</template>

<script>
import {Icon, LangRouterLink} from '@/components/helpers'

export default {
  name: 'UnderlineButton',
  components: {Icon},
  props: {
    icon: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    to: {
      type: Object,
      default: null
    }
  },
  computed: {
    component() {
      if(this.to) return LangRouterLink
      return 'button'
    }
  }
}
</script>