import '@/style.css'

import {createApp} from 'vue'
import App from '@/App.vue'

import * as Sentry from '@sentry/vue'

import {createPinia} from 'pinia'
import i18n from '@/i18n'
import router from '@/router'
import langRouter from '@/extensions/langRouter'

import LOCALE from '@/config/locale.js'
import CONSTANTS from '@/config/CONSTANTS.js'
import {
    useAppStore,
    useAuthStore,
    useModalStore,
    useNoticeStore,
    useOnboardingStore,
    useOrganizationStore
} from '@/stores'

import axios from 'axios'

const app = createApp(App)

if (import.meta.env.VITE_APP_SENTRY_DSN) {
    Sentry.init({
        // https://docs.sentry.io/platforms/javascript/guides/vue/
        app: app,
        dsn: import.meta.env.VITE_APP_SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration({router})
        ],
        tracesSampleRate: 1
    })
}

app.use(createPinia())
    .use(i18n)
    .use(router)
    .use(langRouter, LOCALE.DEFAULT)

app.config.globalProperties.$consts = CONSTANTS

app.config.globalProperties.$app = useAppStore()
app.config.globalProperties.$auth = useAuthStore()
app.config.globalProperties.$modal = useModalStore()
app.config.globalProperties.$notice = useNoticeStore()
app.config.globalProperties.$onboarding = useOnboardingStore()
app.config.globalProperties.$organization = useOrganizationStore()
app.config.globalProperties.$routes = CONSTANTS.ROUTES

try {
    const {data} = await axios.get(`${window.location.origin}/icons.svg`)
    document.body.insertAdjacentHTML('afterbegin', data)
} catch {
}

app.mount('#app')
