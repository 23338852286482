<template>
  <Transition name="notice">
    <aside v-if="$notice.message"
           class="fixed bottom-10 right-10 z-50 bg-lilac-pale pt-3.5 pb-4 px-3 text-deep-indigo-black rounded flex items-center gap-3 text-sm overflow-hidden">
      <span>{{ $notice.message }}</span>
      <button class="text-[8px]" @click.stop="$notice.clear()">
        <Icon id="close"/>
      </button>
      <div class="absolute bottom-0 left-0 h-1 bg-lilac-base w-full notice-progress"
           :style="{animationDuration: `${$notice.ttl}ms`}"/>
    </aside>
  </Transition>
</template>

<script>
import {Icon} from '@/components/helpers'

export default {
  name: 'ServerNotice',
  components: {Icon}
}
</script>

<style>
@keyframes noticeAnimation {
  from {
    @apply w-0;
  }

  to {
    @apply w-full;
  }
}

.notice-progress {
  animation: noticeAnimation linear;
}
</style>