<template>
  <div>
    <h2 class="text-4xl mb-8">{{ $t('media.add_image') }}</h2>
    <form action="" @submit.prevent="">
      <div class="grow rounded-lg w-full mb-6">
        <label for="image" @dragover.prevent @drop.prevent="drop" @dragenter="hover = true" @dragleave="hover = false"
               class="relative flex flex-col justify-center items-center gap-4 border-dashed border-2 h-full rounded p-9 py-14 cursor-pointer overflow-hidden">
          <input type="file" alt="" id="image" name="image" class="hidden" ref="input" @change="fileChange"
                 accept="image/png, image/jpeg">
          <Icon id="pictures" class="text-[32px] pointer-events-none"/>
          <span class="max-w-80 text-center leading-6 pointer-events-none">
            {{ $t('photo.drag_drop') }}
          </span>
          <span :class="['button mt-1', {'pointer-events-none': hover}]">
            {{ $t('photo.choose') }}
          </span>
          <Transition name="fade">
              <span v-if="hover"
                    class="pointer-events-none absolute bg-lilac-pale w-full h-full opacity-60 rounded border-dashed border-2 border-lilac-base z-20"></span>
          </Transition>
          <Transition name="slide-down">
              <span v-if="error" @click.prevent.stop="error = null"
                    class="absolute bottom-0 left-0 w-full bg-error-pale text-error-base py-2 px-3 flex justify-between items-center text-white-900">
                <span class="text-sm">{{ error }}</span>
                <Icon id="close" class="text-[10px]"/>
              </span>
          </Transition>
        </label>
      </div>
    </form>
  </div>
</template>

<script>
import {Icon} from '@/components/helpers'
import {FormInput} from '@/components/form/inputs'
import {FormValidMixin} from '@/components/form/mixins'

export default {
  name: 'ImageImportModal',
  mixins: [FormValidMixin],
  components: {FormInput, Icon},
  data() {
    return {
      error: null,
      hover: false,
      image: null,
    }
  },
  methods: {
    drop(event) {
      this.hover = false
      this.$refs.input.files = event.dataTransfer.files

      this.validateSize(event.dataTransfer.files[0])
    },
    fileChange() {
      this.validateSize(this.$refs.input.files[0])
    },
    validateSize(file) {
      this.error = null

      if (file.size > 2_000_000) {
        this.error = this.$t('errors.max_2mb')
        this.$refs.input.value = null
      } else {
        this.image = URL.createObjectURL(file)
        this.$organization.data.medias.push({
          source: this.image,
          type: 'image'
        })
        this.$modal.close()
      }
    }
  }
}
</script>