export default {
    AI_API_ENDPOINT: {
        CONTENT_REGEN: '/content/regen/invoke'
    },
    API_ENDPOINTS: {
        AUTH: {
            CONFIRM: '/confirm',
            LOGIN: '/login',
            LOGOUT: '/logout',
            SANCTUM_CSRF: '/sanctum/csrf-cookie'
        },
        DATA: '/api/data',
        ORGANIZATION: {
            INDEX: '/api/organizations',
            MEDIA: {
                CAPTION: '/api/medias/:id/caption',
                DELETE: '/api/medias/:id/',
                LOGO: '/api/organizations/:id/logo',
                STORE: '/api/organizations/:id/medias'
            },
            SHOW: '/api/organizations/:id',
            STORE: '/api/organizations',
            UPDATE: '/api/organizations/:id'
        },
        USER: {
            ME: '/api/me'
        }
    },
    MODAL_TYPES: {
        IMAGE_IMPORT: 'image_import',
        MEDIA_CAPTION_UPDATE: 'media_caption_update',
        MEDIA_SHOW: 'media_show',
        YOUTUBE_VIDEO_IMPORT: 'youtube_video_import'
    },
    PURPOSES_COLORS: {
        1: 'light-blue',
        2: 'purple',
        3: 'light-green'
    },
    REGEX: {
        BIRTHDATE: /^\d{4}\/(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])$/,
        EMAIL: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        INSTRUCTIONS: /^[^÷/=@#$ˆ{}|~<>[\]]{2,255}$/,
        NAME: /^[^_!¡?÷?¿/\\+=@#$%ˆ&*{}|~<>;:[\]]{2,255}$/,
        NUMBER: /^\d{1,4}$/,
        PASSWORD: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,255}$/,
        PHONE: /^\d{10}$/,
        SIX_DIGITS: /^\d{6}$/,
        STRING_WITH_NUMBERS: /^[^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,255}$/,
        TEAM: {
            NAME: /^[^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,45}$/,
            SHORT_DESCRIPTION: /^[^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,75}$/
        },
        TEXT: /^[^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,1000}$/,
        URL: /^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
        YEAR: /^20\d{2}$/,
        YOUTUBE: /^((?:https:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/
    },
    ROUTES: {
        AUTH: {
            BASE: 'auth.base',
            LOGIN: 'auth.login'
        },
        ORGANIZATION: {
            EDIT: 'onboarding.edit',
            INDEX: 'onboarding.index',
            SHOW: 'onboarding.show'
        }
    }
}