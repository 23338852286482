export default {
    add: {
        image: 'Ajouter un fichier image',
        youtube: 'Ajouter un lien YouTube'
    },
    ai_employees: 'Employés dédiés à l\'IA',
    cancel: 'Annuler',
    clipboard: {
        copied: 'URL copié dans le presse-papier!',
        failed: 'Oups! Un problème est survenu.'
    },
    code: 'Code d\'authentification',
    contact: {
        body: 'Bonjour, j\'ai visité la plateforme Miria récemment et j\'ai remarqué que ...',
        subject: 'Suggestions d\'amélioration de la plateforme Miria'
    },
    contact_us: 'Contactez-nous!',
    data: {
        analysis: 'Analyse des données',
        gathering: 'Récolte des données',
        generation: 'Génération des contenus',
        transformation: 'Traitement des données'
    },
    desktop_only: 'Version mobile à venir',
    did_you_know: 'Saviez-vous que...',
    email: 'Courriel',
    enter: 'Entrée',
    experience_years: 'Années d\'expérience',
    keywords: {
        hide: 'Cacher les mots-clés',
        show: 'Explorer les mots-clés'
    },
    login: {
        codes: 'Pour la phase de développement, les codes d\'authentification doivent être récupérés au <a href="CODES_URL" target="_blank">CODES_URL</a>, et non par courriel.',
        description: 'Un code à 6 chiffres vous sera envoyé à l\'adresse ci-dessous pour compléter l\'authentification. <br>S\'il s\'agit de votre première visite sur la plateforme, entrez l\'adresse courriel que vous souhaitez associer à votre organisation.',
        title: 'Connexion'
    },
    logout: 'Se déconnecter',
    max_selection: ':current sélectionnés sur :max maximum',
    media: {
        add_image: 'Ajouter une image',
        add_legend: 'Ajouter une légende au média',
        add_youtube: 'Ajouter une vidéo Youtube'
    },
    miria: {
        ask: 'Demander à Miria'
    },
    password: 'Mot de passe',
    photo: {
        choose: 'Choisir une Photo',
        drag_drop: 'Faites glisser et déposez ou sélectionnez une photo depuis votre ordinateur'
    },
    press_on: 'Appuyez sur',
    projects_completed: 'Projets réalisés',
    private: 'Privé',
    public: 'Public',
    save: 'Sauvegarder',
    send: 'Envoyer',
    share: 'Partager ce profil',
    shift: 'Maj',
    start: 'Commencer',
    status: 'Statut',
    submit: 'Soumettre',
    to_add_links: 'pour ajouter des liens',
    trademark: '© 2024. Tous droits réservés. Miria est un projet propulsé par le Conseil de l\'innovation du Québec',
    visit_website: 'Visiter le site web'
}