<template>
  <p class="w-full h-full flex items-center justify-center fixed top-0 left-0 bg-deep-indigo-black z-50">
    <span class="loader"/>
  </p>
</template>

<script>
export default {
  name: 'Loader'
}
</script>