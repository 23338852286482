<template>
  <div>
    <img v-if="$modal.data.type === 'image'" :src="$modal.data.source" alt="" :class="mediaClasses">
    <iframe v-if="$modal.data.type === 'youtube'" :src="embed" frameborder="0" :class="[mediaClasses , 'aspect-video']"/>
  </div>
</template>

<script>
export default {
  name: 'MediaShowModal',
  data() {
    return {
      mediaClasses: 'w-full rounded-lg'
    }
  },
  computed: {
    embed() {
      if(!this.$modal.data.video_id) return null

      return `https://www.youtube.com/embed/${this.$modal.data.video_id}?autoplay=0&cc_load_policy=1&controls=2&rel=0&enablejsapi=1`
    }
  },
}
</script>