import {defineStore} from 'pinia'
import CONSTANTS from '@/config/CONSTANTS.js'
import {baseApi} from '@/extensions/http/index.js'
import {useOnboardingStore} from '@/stores/onboarding.js'
import {useNoticeStore} from '@/stores/notice.js'

export const useOrganizationStore = defineStore('organization', {
    state: () => {
        return {
            scraping: {
                analysis: 0,
                interval: null,
                gathering: 0.01,
                generation: 0,
                transformation: 0
            },
            data: null,
            updating: false
        }
    },
    actions: {
        async deleteMedia(id) {
            try {
                this.updating = true
                await baseApi.delete(CONSTANTS.API_ENDPOINTS.ORGANIZATION.MEDIA.DELETE.replace(':id', id))
            } catch (exception) {
                useNoticeStore().report('errors.server.media.delete')
            } finally {
                this.updating = false
            }
        },
        async get(id) {
            try {
                this.updating = true
                const {data} = await baseApi.get(CONSTANTS.API_ENDPOINTS.ORGANIZATION.SHOW.replace(':id', id))

                if (id === this.data?.id) {
                    this.sync(data)
                }

                return data
            } catch (exception) {
                useNoticeStore().report('errors.server.organization.show')
            } finally {
                this.updating = false
            }
        },
        async store(payload) {
            try {
                this.updating = true
                const {data} = await baseApi.post(CONSTANTS.API_ENDPOINTS.ORGANIZATION.STORE, payload)
                this.sync(data.organization)
                this.connectChannel()

                useOnboardingStore().forward()
            } catch (exception) {
                useNoticeStore().report("errors.server.organization.store")
            } finally {
                this.updating = false
            }
        },
        async update(payload) {
            try {
                this.updating = true
                await baseApi.put(CONSTANTS.API_ENDPOINTS.ORGANIZATION.UPDATE.replace(':id', this.data.id), payload)

                useOnboardingStore().forward()
            } catch (exception) {
                useNoticeStore().report("errors.server.organization.update")
            } finally {
                this.updating = false
            }
        },
        async updateMediaCaption(media, caption) {
            try {
                this.updating = true
                await baseApi.post(CONSTANTS.API_ENDPOINTS.ORGANIZATION.MEDIA.CAPTION.replace(':id', media.id), {
                    caption: caption,
                    type: media.type
                })
            } catch (exception) {
                useNoticeStore().report("errors.server.media.caption")
            } finally {
                this.updating = false
            }
        },
        async uploadLogo(payload) {
            try {
                this.updating = true

                if (payload) {
                    await baseApi.post(CONSTANTS.API_ENDPOINTS.ORGANIZATION.MEDIA.LOGO.replace(':id', this.data.id), payload, {
                        headers: {'Content-Type': 'multipart/form-data'}
                    })
                }

                useOnboardingStore().forward()
            } catch (exception) {
                useNoticeStore().report("errors.server.media.logo")
            } finally {
                this.updating = false
            }
        },
        async uploadMedias(payload) {
            try {
                this.updating = true
                await baseApi.post(CONSTANTS.API_ENDPOINTS.ORGANIZATION.MEDIA.STORE.replace(':id', this.data.id), payload, {
                    headers: {'Content-Type': 'multipart/form-data'}
                })

                useOnboardingStore().forward()
            } catch (exception) {
                useNoticeStore().report("errors.server.media.upload")
            } finally {
                this.updating = false
            }
        },
        sync(organization) {
            this.data = organization

            if (this.data && !this.data.medias) {
                this.data.medias = this.data.scraped_medias
            }
        },
        syncScraping(type, value) {
            return this.scraping[type] = value
        },
        connectChannel() {
            if (this.data && window.Echo) {
                window.Echo.private(`organizations.${this.data.id}`)
                    .listen('.organization.generated', async () => {
                        this.clearAnalysisInterval()
                        this.syncScraping('generation', 1)
                        const organization = await this.get(this.data.id)
                        this.sync(organization)
                        setTimeout(useOnboardingStore().forward, 2000)
                    })
                    .listen('.scraping.analyzed', () => {
                        this.clearAnalysisInterval()
                        this.syncScraping('analysis', 1)
                        this.setAnalysisInterval('generation')
                    })
                    .listen('.scraping.scraped', () => {
                        this.clearAnalysisInterval()
                        this.syncScraping('gathering', 1)
                        this.setAnalysisInterval('transformation')
                    })
                    .listen('.scraping.synced', () => {
                        this.clearAnalysisInterval()
                        this.syncScraping('transformation', 1)
                        this.setAnalysisInterval('analysis')
                    })
                    .listen('.scraping.progress', (progress) => {
                        const progression = (progress.scraping_progress || 1) / (progress.scraping_target || 100)
                        if (progression > this.scraping.gathering) this.syncScraping('gathering', progression)
                    })

                this.setAnalysisInterval('gathering')
            }
        },
        disconnectChannel() {
            if (this.data && window.Echo) {
                window.Echo.leave(`organizations.${this.data.id}`)
            }
        },
        clearAnalysisInterval() {
            if (this.scraping.interval) {
                clearInterval(this.scraping.interval)
            }
        },
        setAnalysisInterval(category) {
            if (this.scraping[category] < 1) {
                this.scraping.interval = 0.001

                this.scraping.interval = setInterval(() => {
                    if (this.scraping[category] + 0.01 < 1) this.scraping[category] += 0.01
                }, 1000)
            }
        },
        reset() {
            this.scraping = {
                analysis: 0,
                interval: null,
                gathering: 0.01,
                generation: 0,
                transformation: 0
            }
            this.data = null
            this.updating = false
        }
    }
})