export default {
    max_2mb: 'Fichier trop lourd. (Max 2 Mo)',
    special_characters: 'Les caractères spéciaux sont interdits.',
    server: {
        auth: {
            confirm: 'L\'authentification n\'a pu être complétée.',
            login: 'L\'adresse courriel fournie est invalide ou indisponible.',
            logout: 'Un problème est survenu lors de la déconnexion.',
            reconnect: 'Session expirée.'
        },
        content: {
            regen: 'Le contenu n\'a pas pu être regénéré.'
        },
        init: 'Un problème est survenu à l\'initialisation, veuillez rafraîchir la page.',
        media: {
            caption: 'La légende n\'a pas pu être mise à jour.',
            delete: 'Le média n\'a pas pu être supprimé.',
            logo: 'Le logo n\'a pas pu être mis à jour.',
            upload: 'Les médias n\'ont pas pu être mis à jour.'
        },
        organization: {
            index: 'Les organisations n\'ont pas pu être récupérées.',
            show: 'L\'organisation n\'a pas pu être récupérée.',
            store: 'L\'organisation n\'a pas pu être crée.',
            update: 'L\'organisation n\'a pas pu être mise à jour.',
        }
    }
}