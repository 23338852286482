<template>
  <Transition name="fade">
    <aside v-if="$modal.type" @click.stop="$modal.close"
           class="fixed top-0 left-0 z-50 w-full h-full bg-modal backdrop-blur-xl p-10 flex items-center justify-center cursor-pointer">
      <div class="bg-deep-indigo-black shadow-organization w-[70dvw] max-h-[95dvh] cursor-default rounded-lg relative"
           @click.stop>
        <button class="social absolute top-5 right-5 bg-deep-indigo-heavy" type="button"
                @click.stop.prevent="$modal.close">
          <Icon id="close" class="text-xs"/>
        </button>
        <component :is="modal" class="p-10"/>
      </div>
    </aside>
  </Transition>
</template>

<script>
import {Icon} from '@/components/helpers'
import {
  ImageImportModal,
  MediaCaptionUpdateModal,
  MediaShowModal,
  YoutubeVideoImportModal
} from '@/components/modal/blocks'

export default {
  name: 'Modal',
  components: {Icon},
  computed: {
    modal() {
      switch (this.$modal.type) {
        case this.$consts.MODAL_TYPES.IMAGE_IMPORT:
          return ImageImportModal
        case this.$consts.MODAL_TYPES.MEDIA_CAPTION_UPDATE:
          return MediaCaptionUpdateModal
        case this.$consts.MODAL_TYPES.MEDIA_SHOW:
          return MediaShowModal
        case this.$consts.MODAL_TYPES.YOUTUBE_VIDEO_IMPORT:
          return YoutubeVideoImportModal
        default:
          return null
      }
    }
  },
}
</script>